import TimelineLite from 'gsap/TimelineLite';
import 'gsap/CSSPlugin';
import 'gsap/ScrollToPlugin';

import forEach from 'lodash/forEach';


function NavigationInternalCtrl() {
	//*******************
	// Contrato externo
	//*******************

	return {
		init: init
	};

	//*******************
	// Implementación
	//*******************
	function init(){
		bindEvents();
	}

	function bindEvents(){
		//Buscamos todos los elementos a que comienzan por # (son anchors internos)
		var anchors=document.querySelectorAll("a[href^='#']");
		//Asociamos el click con la función que hace el desplazamiento animado
		forEach(anchors, function(a){
			a.addEventListener("click", gotoAnchor);
		});
	}

	function gotoAnchor(e){
		//Buscamos a cuál target estamos apuntando:		
		var anchor=e.target.getAttribute("href") || this.getAttribute("href");
		//Verificamos cuántos elementos existen con el id=anchor
		var target=document.querySelectorAll(anchor);
		//Tiempo en que se hace el desplazamiento:
		var speed=1.5;

		if (target.length>0){
			//Evitamos que el browser lo lleve directamente
			e.preventDefault();
			//Hacemos la animación
			TweenLite.to(window, speed, {scrollTo:{y:anchor}});			
			//Agregamos el anchor a la barra de navegación
			if (window.history && window.history.pushState) {
	      history.pushState("", document.title, anchor);
	    }
		}
	}

}


export default NavigationInternalCtrl();
