import TimelineLite from 'gsap/TimelineLite';
import 'gsap/CSSPlugin';

import forEach from 'lodash/forEach';


function CarouselGsapCtrl() {
	//*******************
	// Contrato externo
	//*******************
  //Tiempo que se mantiene en pantalla cada slide
  var SLIDE_SCREEN_TIME = 2;

  //TODO: definir como identificar los slides
  var SELECTOR_BASE = '.carousel-gsap';
	
	//Cantidad de loops antes que se detenga el slider
  var MAX_LOOPS=15;

  //Contador de los loops que se llevan
  var loops=0;


	return {
		init: init
	};

	//*******************
	// Implementación
	//*******************
	function init(newOptions){
	    var slides = document.querySelectorAll(SELECTOR_BASE + ' .item');
	    if (!slides || slides.length===0){
	    	return;
	    }
	   	var animation = buildAnimation(slides, SLIDE_SCREEN_TIME, restart);
	   	
	   	animation.eventCallback("onComplete", restart, [animation]);
		animation.eventCallback("onReverseComplete", restart, [animation]);
	   	animation.play();   	
	}

	function restart(animation){
		loops+=1;
		if (loops>MAX_LOOPS){
			return;	
		}
		if (animation.reversed()){
			animation.restart();
		}else{
			TweenLite.delayedCall(SLIDE_SCREEN_TIME, function(){animation.reverse();});
		}
	}


	function buildAnimation(slides, delay){
		//Se crea el timeline en pausado para que se pueda manipular fácilmente 
	  var res = new TimelineLite({paused: true});
	  //Se busca el tamaño del slide para poder desplazarlo correctamente
	  var size=slides[0].offsetWidth;
	  //Velocidad de desplazamiento entre slides
	  var speed=1.5;
	  //Tiempo que se espera en el stagger para animar cada elemento
	  //  en este caso se requieren que sean simultáneos para que se vean "pegados"
	  var separation = 0;

	  var from;
	  var to;

	  forEach(slides, function(s, row){
	  	if (row+1<slides.length){
	  		from = {
	  			//El default es true y hace que se superpongan los slides
	  			immediateRender:false, 
	  			//Se coloca en visible
	  			autoAlpha:1, 
	  			cycle:{
	  				//Utilizamos el ciclo para recorrer todos los slides
	  				//se asigna una posición X dependiendo de la lámina que estamos mostrando (row)
	  				x:function(i){return (i-row)*size;}}
	  			};
	  		to = {
	  			cycle:{
	  				x:function(i){return (i-row-1)*size;}
	  			}
	  		};
				//Se simula que todos los slides están uno al lado del otro
				res.staggerFromTo(slides, speed, from,to , separation, "+="+delay);
	  	}
	  });
    return res;
  }

}


export default CarouselGsapCtrl();
