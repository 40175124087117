
//Slider principal en el home
import mainSlider from './modules/main-slider';

//Slider para los testimoniales
import carouselGsap from './modules/carousel-gsap';

import navigationInternal from './modules/navigation-internal';

//Inicializamos el slider principal
mainSlider.init();

//Inicializamos el slider de los testimoniales
carouselGsap.init();

//inicializamos la barra de navegacion
navigationInternal.init();

