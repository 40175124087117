
import merge from 'lodash/merge';

import TimelineLite from 'gsap/TimelineLite';
import 'gsap/CSSPlugin';
import 'gsap/EasePack';

function SliderCtrl() {

    //*******************
    // Contrato externo
    //*******************
    var _options = {

    };

    var currentSlide;
    var lenSlides;
    
    var SLIDE_SCREEN_TIME = 2;
    var MAX_SLIDES=20;

    //TODO: definir como identificar los slides
    var SELECTOR_BASE = '#slide-';

    var interactions=0;

    return {
        init: init
    };

    //*******************
    // Implementación
    //*******************
    function init(newOptions) {

        _options = merge(_options, newOptions);

        currentSlide = 0;
        //TODO:Buscar el len en el html
        lenSlides = 3;
        
        slideOutTimeline(SELECTOR_BASE + currentSlide, looping, 0);

    }

    function looping(){
      interactions += 1;
      if (currentSlide<lenSlides){
        currentSlide+=1;
      }else{
        currentSlide=0;
      }        

      if (interactions>MAX_SLIDES){
        slideInTimeline(SELECTOR_BASE + currentSlide, null);
        return;
      }
      slideInTimeline(SELECTOR_BASE + currentSlide, function(){slideOutTimeline(SELECTOR_BASE + currentSlide, looping, SLIDE_SCREEN_TIME);});
    }


    function slideInTimeline(selector, onComplete){
      var res = new TimelineLite({ onComplete:onComplete?onComplete:null});
      res  
        .set(selector, {display: 'block'})      
        .fromTo(selector + " .imk-img-container", 1.5,  {x: 1200 , ease:Back.easeInOut},{x:0})
        .staggerFromTo(selector + " .slider-caption", 0.5, {x: -1000, ease:Power1.easeInOut },{x:0}, 0.3 , "-=1.3");
      return res;
    }

    function slideOutTimeline(selector, onComplete, timeToStart){
      var res = new TimelineLite({onComplete: onComplete?onComplete:null});
      res            
        .fromTo(selector + " .imk-img-container", 1.5, {x:0},{x: 1200 , ease:Back.easeInOut}, timeToStart)
        .staggerFromTo(selector + " .slider-caption", 0.5, {x:0},{x: -1000, ease:Power1.easeInOut}, 0.3 , "-=1.3")
        .set(selector, {display: 'none'});
      return res;
    }




}

export default SliderCtrl();
